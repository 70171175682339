@font-face {
	font-family: 'Boston';
	src: url('../../public/fonts/BostonLight.woff');
	font-weight: 400;
}
@font-face {
	font-family: 'Boston';
	src: url('../../public/fonts/BostonRegular.woff');
	font-weight: 500;
}
@font-face {
	font-family: 'Boston';
	src: url('../../public/fonts/BostonSemiBold.woff');
	font-weight: 600;
}
@font-face {
	font-family: 'Boston';
	src: url('../../public/fonts/BostonBold.woff');
	font-weight: 700;
}
